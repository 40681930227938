export const runReportDayData = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  // 图标右侧上方的下载按钮等
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      magicType: { show: true, type: ['line', 'bar'] },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  legend: {
    data: ['通过', '失败', '总执行数', '通过率']
  },
  xAxis: [
    {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed'],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '执行用例数',
      min: 0,
      // max: 1000,
      // interval: 50,
      axisLabel: {
        interval: 'auto',
        formatter: '{value} 次'
      }
    },
    {
      type: 'value',
      name: '通过率',
      min: 0,
      // max: 100,
      // interval: 5,
      axisLabel: {
        interval: 'auto',
        formatter: '{value} %'
      }
    }
  ],
  series: [
    {
      name: '通过',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' 次';
        }
      },
      data: [
        20.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
      ]
    },
    {
      name: '失败',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' 次';
        }
      },
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 13.6, 160.2, 32.6, 20.0, 6.4, 3.3
      ]
    },
    {
      name: '总执行数',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value + ' 次';
        }
      },
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
      ]
    },
    {
      name: '通过率',
      type: 'line',
      yAxisIndex: 1,
      tooltip: {
        valueFormatter: function (value) {
          return value + ' %';
        }
      },
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
    }
  ]
}
