<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>API自动化报表</el-breadcrumb-item>
      <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-card class="box-card">
    <div ref="chart" style="width: auto;height:600px"></div>
  </el-card>
  </div>
</template>

<script>
// import echarts from 'echarts'
import * as echarts from 'echarts'
import { runReportDayData } from '@/views/chart_report/run_report_day.js'
import { RunReportDay } from '@/services/dashboard.js'
export default {
  name: 'Home',
  data () {
    return {
      qe: ''
    }
  },
  created () {},
  mounted () {
    this.echartsReport()
  },
  methods: {
    async echartsReport () {
      const { data: res } = await RunReportDay(this.qe)
      console.log(res, '<== 首页看板返回数据')
      const chart = this.$refs.chart
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart && res.data) {
        const myChart = echarts.init(chart)
        const xAxisData = []
        const seriesPassCount = []
        const seriesFailCount = []
        const seriesSumCount = []
        const seriesPassRate = []
        res.data.forEach(item => {
          xAxisData.push(item.created_time)
          seriesPassCount.push(item.pass_count_sum)
          seriesFailCount.push(item.failed_count_sum)
          seriesSumCount.push(item.test_count_sum)
          seriesPassRate.push(item.pass_rate)
        });
        // runReportDayData.yAxis[0].max = Math.max(...seriesSumCount) + Math.max(...seriesSumCount) * 0.2
        runReportDayData.xAxis[0].data = xAxisData
        runReportDayData.series[0].data = seriesPassCount
        runReportDayData.series[1].data = seriesFailCount
        runReportDayData.series[2].data = seriesSumCount
        runReportDayData.series[3].data = seriesPassRate
        const option = runReportDayData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
