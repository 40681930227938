import request from '@/utils/request'

// 首页看板数据
export const RunReportDay = (data) => {
  return request({
    method: 'POST',
    url: '/runCaseReportDate',
    data
  })
}
